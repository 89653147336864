<template>
  <div class="about_container">
    <div class="title">
      <div class="font_zh">关于我们</div>
      <div class="font_en">ABOUT US</div>
    </div>
    <div class="content">
      <p>
        南京恒鼎锐网络科技有限公司于2015年成立于中国南京市玄武区，是一家针对企事业单位信息化平台建设方案的解决商。自成立以来，公司站在技术市场的最前端，在各项高新技术层面均积累了丰富的经验。作为企业两化融合解决方案的提供商，其在电子商务平台的搭建、企业ERP平台搭建、企事业单位OA平台搭建、企事业个性化软件定制、App、小程序软件的定制等方面也颇具实力，其独特且严谨的开发流程，获得了客户高度的认可和无数好评。
        核心竞争力：依托公司优秀的开发管理人才，为客户提供系统的定制、开发以及解决方案的制定、技术与人才外包等方面的专业、持久的个性化服务。
        案例：RPA、SEO引擎优化、小程序开发、移动APP的开发、ERP系统定制化开发、OA办公系统定制化、电子商务平台定制化、即时通讯系统、政府公众平台的定制开发等等。
      </p>
      <img v-lazy="require('@/assets/Home/home_about.png')" alt="" />
    </div>
  </div>
</template>
<script>
import { reactive, toRefs } from 'vue'

export default {
  name: 'PracticeCertification',
  setup() {
    const state = reactive({
    })

    return {
      ...toRefs(state),
    }
  }
}
</script>
<style lang="less" scoped>
.about_container {
  width: 1300px;
  margin: 70px auto;

  .title {
    text-align: center;
    width: 238px;
    .font_en {
      height: 25px;
      font-size: 18px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #e9e9e9;
      line-height: 25px;
      margin-top: 9px;
    }
    .font_zh {
      height: 55px;
      font-size: 34px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #404040;
      line-height: 48px;
      background: url("@/assets/title_bg.png") no-repeat;
      background-size: 238px 22px;
      background-position: 0px 27px;
    }
  }
  .content {
    margin-top: 26px;
    display: flex;
    justify-content: space-between;
    p {
      width: 609px;
      height: 243px;
      font-size: 15px;
      color: #404040;
      line-height: 27px;
      margin: 0;
    }
    img {
      width: 558px;
      height: 243px;
    }
  }
}
</style>