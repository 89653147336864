<template>
  <div class="home_Practice">
    <div class="top">
      <div class="title">
        <div class="font_en">NEWS INFORMATION</div>
        <div class="font_zh">新闻中心</div>
      </div>
      <div class="more" @click="toLink">更多 →</div>
    </div>
  </div>
  <ul class="news">
    <li
      v-for="(item, index) in companyList"
      :key="index"
      @click="doDetail(item.id)"
    >
      <img :src="item.proviewPic" alt="" />
      <div class="news_right">
        <div class="news_title">{{ item.title }}</div>
        <div class="news_content">{{ item.content }}</div>
      </div>
    </li>
  </ul>
</template>
<script>
import { reactive, toRefs } from "vue";
import { useRouter } from "vue-router";
export default {
  name: "PracticeCertification",
  setup() {
    const state = reactive({
      companyList: [
        {
          id: 12345,
          date: "2021-10-28",
          proviewPic: require("@/assets/news/1/proview.png"),
          title:
            "【物联网动态】温室大棚如何度过凛冽寒冬？智慧物联网系统来保驾护航播",
          content:
            " 相信大家通过新闻都有所了解，今年的冬天是一个冷冬。 根据国家气候中心称，2021年冬季我国将出现拉尼娜现象，对我国的气温有影响，有一种可能，那就是大概率会迎来冷冬。 那咱们的农作物如何平稳的度过这个寒冬呢？那就不得不说一说智能温室物联网系统了，那咱们的智能温室物联网系统是如何保证农作物平稳度过寒冬呢？",
        },
        {
          id: 12346,
          date: "2021-10-15",
          proviewPic: require("@/assets/news/2/1.png"),
          title:
            "【物联网动态】市场大热点：物联网热度不减，入局前帮你梳理产业链",
          content:
            "“物联网”这个词自诞生之日起，就一直热度不减，尤其是近几年，越来越多的科技企业开始入局物联网，加之国家各项发展政策，让整个物联网行业的未来充满着想象空间。 物联网产业链大致可以分为四个层次：感知识别层、网络传输层、平台控制层、终端应用层。今天，我们就来聊一聊物联网产业链的第一步：感知识别层。 传感识别层：核心器件传感器将优先受益，传感产业将迎第二春",
        },
        {
          id: 12347,
          date: "2021-10-12",
          proviewPic: require("@/assets/news/3/proview.png"),
          title: "【物联网动态】补齐关键核心技术短板 八部委推行物联网行动计划",
          content:
            "物联网是以感知技术和网络通信技术为主要手段，实现人、机、物的泛在连接，提供信息感知、信息传输、信息处理等服务的基础设施。物联网是新基建的重要组成部分，“十四五”规划将其纳入了七大数字经济重点产业。 但与此同时，我国物联网产业发展还存在一些需要解决的问题，如关键核心技术存在短板、产业生态不够健全、规模化应用不足、支撑体系难以满足产业发展需要等。",
        },
        {
          id: 12348,
          date: "2021-08-24",
          proviewPic: require("@/assets/news/3/1.jpeg"),
          title: "【物联网动态】5G物联网改变世界的五种方式",
          content:
            "5G网络的普及为人们进一步拓宽了想象的边界，其所衍生的应用已开始延伸到生活的各个层面。图为在西班牙巴塞罗那，工作人员在世界移动通信大会法国电信公司展厅演示利用5G技术搭配VR（虚拟现实）眼镜实现远程遥控驾驶（资料照片）。技术的进步通常是为了让我们的生活变得更加美好！从4G到5G，可不仅仅是上网速度更快，而是一次改变社会的信息革命。",
        },
        {
          id: 12349,
          date: "2021-08-13",
          proviewPic: require("@/assets/news/4/proview.png"),
          title: "【RPA动态】RPA在零售行业中的11个应用案例",
          content:
            "随着世界各国进入工业4.0时代，机器人流程自动化(RPA)在所有领域都获得了发展，这主要是因为很多行业组织已经开始意识到流程自动化的重要性，而提高效率已成为首要事项。零售行业尤其如此。客户希望获得更快的购物体验并减少出错。根据eMarketer公司的调查数据，全球零售行业2020年的销售额与2019年相比下降了3%，但同期的电子商务销售额增长了27%以上。此外，预计到2023年，在线销售额将占零售业的四分之一。",
        },
        {
          id: 12350,
          date: "2021-08-09",
          proviewPic: require("@/assets/news/4/1.png"),
          title:
            "【物联网新闻】下一个半导体？“宅经济”助推物联网需求爆发A股相关公司业绩开挂",
          content:
            "无论是从渠道端，还是供货端，智能物联网(AIoT)的火爆程度已经得到多方反馈和业绩印证。虽然新冠疫情扰乱了供应链，电子行业普遍面临缺芯涨价、运力成本上升等问题，导致利润率受压制，但是智能物联网持续落地、自主生态圈的打造已经成为势不可挡的趋势。“从我们获悉的情况来看，物联网领域是本轮芯片缺货重灾区。”在日前行业交流会上，深圳华强旗下子公司湘海电子董事长杨林表示，现在物联网端的客户反映订单有的是，就是缺芯片；如果芯片能保障，订单能多接一倍。",
        },
      ],
    });
    const router = useRouter();
    const toLink = () => {
      router.push("/news");
    };
    const doDetail = (id) => {
      router.push({ path: "/news/detail", query: { id: id } });
    };

    return {
      ...toRefs(state),
      toLink,
      doDetail,
    };
  },
};
</script>
<style lang="less" scoped>
.home_Practice {
  width: 100%;
  max-width: 1920px;
}
.top {
  position: relative;
  margin: 50px auto 30px;
  .title {
    text-align: center;
    .font_en {
      font-size: 16px;
      font-weight: 600;
      color: #e9e9e9;
      line-height: 16px;
    }
    .font_zh {
      font-size: 21px;
      font-weight: 600;
      color: #333;
      line-height: 20px;
    }
  }
  .more {
    width: 68px;
    height: 23px;
    line-height: 23px;
    text-align: center;
    background: #f2f2f2;
    border-radius: 11px;
    font-size: 13px;
    color: #b5b5b5;
    position: absolute;
    right: 0;
    top: 50%;
    margin-top: -11.5px;
  }
  .more:hover {
    cursor: pointer;
    color: #ef0400;
  }
}
.news {
  padding: 0 10px;
  li {
    display: flex;
    margin-bottom: 10px;
    img {
      display: block;
      width: 94px;
      height: 70px;
      border-radius: 8px;
      margin-right: 10px;
    }
    .news_right {
      flex: 1;
      .news_title {
        font-size: 14px;
        color: #333333;
        font-weight: bold;
        margin-bottom: 5px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }
      .news_content {
        font-size: 12px;
        color: #666666;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }
  }
}
</style>
