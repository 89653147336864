<template>
  <div class="practice_certificati_box">
    <div class="title">
      <div class="font_en">PRACTICE CERTIFICATION</div>
      <div class="font_zh">实践数据实力认证</div>
    </div>
    <div class="practice_certificati">
      <div class="practice_box">
        <div>10+年</div>
        <span>专注服务</span>
      </div>
      <div class="practice_box">
        <div>98%</div>
        <span>综合好评率</span>
      </div>
      <div class="practice_box">
        <div>500+</div>
        <span>驻场服务支持</span>
      </div>
      <div class="practice_box">
        <div>2000+</div>
        <span>忠实客户</span>
      </div>
    </div>
  </div>
</template>
<script>
import { reactive, toRefs } from "vue";

export default {
  name: "PracticeCertification",
  setup() {
    const state = reactive({});

    return {
      ...toRefs(state),
    };
  },
};
</script>
<style lang="less" scoped>
.practice_certificati_box {
  margin-top: 30px;
  width: 100%;
  .title {
    text-align: center;
    .font_en {
      font-size: 16px;
      font-weight: 600;
      color: #e9e9e9;
      line-height: 16px;
    }
    .font_zh {
      font-size: 21px;
      font-weight: 600;
      color: #333;
      line-height: 20px;
    }
  }

  .practice_certificati {
    margin-top: 30px;
    width: 100%;
    height: 160px;
    background: url("@/assets/Home/practice_bg.png");
    background-size: 100% 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 40px 15px;

    .practice_box {
      width: 40%;
      display: flex;
      align-items: flex-end;
      color: #fff;
      & > div {
        width: 50%;
        font-size: 18px;
        font-weight: bold;
      }
      & > span {
        font-size: 12px;
      }
    }
  }
}
</style>
