import { createApp } from "vue";
import Antd from "ant-design-vue";
import "ant-design-vue/dist/antd.css";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Lazyload from "vue3-lazyload";

createApp(App)
  .use(store)
  .use(router)
  .use(Antd)
  .use(Lazyload, {
    loading: "./assets/logo.png", //可以指定加载中的图像
    error: "./assets/logo.png", //可以指定加载失败的图像
  })
  .mount("#app");

router.afterEach((to, from, next) => {
  if (to.fullPath !== "/#provide" && !to.fullPath.includes("about")) {
    window.scrollTo(0, 0);
  }
});
