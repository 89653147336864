import instance from "@/utils/request";

export const getPhoneAPI = (data) => {
  return instance({
    method: "post",
    url: "/CrmLeads/sendMsg",
    data,
  });
};
export const saveAndUpdateAPI = (data) => {
    return instance({
      method: "post",
      url: "/CrmLeads/saveAndUpdate ",
      data,
    });
  };
