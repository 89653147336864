<template>
  <div class="home_Practice">
    <div class="top">
      <div class="title">
        <div class="font_zh">新闻中心</div>
        <div class="font_en">NEWS INFORMATION</div>
      </div>
      <div class="more" @click="toLink">更多 →</div>
    </div>
  </div>
  <div class="news">
    <ul>
      <li v-for="(item, index) in companyList" :key="index">
        <div class="date">
          <span>{{ item.date.split("-")[2] }}</span>
          <span>{{
            item.date.split("-")[0] + "-" + item.date.split("-")[1]
          }}</span>
        </div>
        <div class="right">
          <div class="title">{{ item.title }}</div>
          <div class="content">{{ item.content }}</div>
        </div>
        <div class="btn" @click="doDetail(item.id)">查看详情</div>
      </li>
    </ul>
  </div>
</template>
<script>
import { reactive, toRefs } from 'vue'
import {useRouter} from 'vue-router'
export default {
  name: 'PracticeCertification',
  setup() {
    const state = reactive({
      companyList: [
        {id:12345, date: '2024-3-01', title: '【物联网动态】温室大棚如何度过凛冽寒冬？智慧物联网系统来保驾护航播', content: ' 相信大家通过新闻都有所了解，今年的冬天是一个冷冬。 根据国家气候中心称，2021年冬季我国将出现拉尼娜现象，对我国的气温有影响，有一种可能，那就是大概率会迎来冷冬。 那咱们的农作物如何平稳的度过这个寒冬呢？那就不得不说一说智能温室物联网系统了，那咱们的智能温室物联网系统是如何保证农作物平稳度过寒冬呢？' },
        {id:12346, date: '2024-2-26', title: '【物联网动态】市场大热点：物联网热度不减，入局前帮你梳理产业链', content: '“物联网”这个词自诞生之日起，就一直热度不减，尤其是近几年，越来越多的科技企业开始入局物联网，加之国家各项发展政策，让整个物联网行业的未来充满着想象空间。 物联网产业链大致可以分为四个层次：感知识别层、网络传输层、平台控制层、终端应用层。今天，我们就来聊一聊物联网产业链的第一步：感知识别层。 传感识别层：核心器件传感器将优先受益，传感产业将迎第二春' },
        {id:12347, date: '2024-1-12', title: '【物联网动态】补齐关键核心技术短板 八部委推行物联网行动计划', content: '物联网是以感知技术和网络通信技术为主要手段，实现人、机、物的泛在连接，提供信息感知、信息传输、信息处理等服务的基础设施。物联网是新基建的重要组成部分，“十四五”规划将其纳入了七大数字经济重点产业。 但与此同时，我国物联网产业发展还存在一些需要解决的问题，如关键核心技术存在短板、产业生态不够健全、规模化应用不足、支撑体系难以满足产业发展需要等。' },
      ]
    })
    const router = useRouter()
    const toLink = () =>{
      router.push('/news')
    }
    const doDetail = (id) =>{
      router.push({path:'/news/detail',query:{id:id}})
    }

    return {
      ...toRefs(state),
      toLink,
      doDetail
    }
  }
}
</script>
<style lang="less" scoped>
.home_Practice {
  width: 100%;
  max-width: 1920px;
}
.top {
  position: relative;
  width: 1300px;
  margin: 50px auto;
  .title {
    text-align: center;
    .font_en {
      height: 25px;
      font-size: 18px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #e9e9e9;
      line-height: 25px;
      margin-top: 9px;
    }
    .font_zh {
      height: 48px;
      font-size: 34px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #404040;
      line-height: 48px;
      background: url("@/assets/title_bg.png") no-repeat;
      background-size: 238px 25px;
      background-position: 50% 23px;
    }
  }
  .more {
    width: 68px;
    height: 23px;
    line-height: 23px;
    text-align: center;
    background: #f2f2f2;
    border-radius: 11px;
    font-size: 13px;
    color: #b5b5b5;
    position: absolute;
    right: 0;
    top: 50%;
    margin-top: -11.5px;
  }
  .more:hover {
    cursor: pointer;
    color: #ef0400;
  }
}

.news {
  height: 366px;
  margin: 20px auto;
  background: url("@/assets/Home/news_bg.png") no-repeat;
  background-size: 100% 100%;
  position: relative;
  display: flex;
  justify-content: center;
  ul {
    display: flex;
    width: 1300px;
    position: absolute;
    top: -22px;
    li {
      height: 412px;
      display: flex;
      padding: 50px 15px;
      margin-right: 10px;
      position: relative;
      .date {
        span {
          display: block;
        }
        span:nth-child(1) {
          font-size: 38px;
          font-weight: 600;
          color: #333333;
          line-height: 53px;
        }
        span:nth-child(2) {
          font-size: 18px;
          font-weight: 600;
          color: #333333;
          line-height: 25px;
        }
      }
      .right {
        margin-left: 18px;
        .title {
          font-size: 16px;
          color: #404040;
          line-height: 22px;
          width: 288px;
          border-bottom: 1px dashed #dddddd;
          padding: 10px 0;
        }
        .content {
          width: 300px;
          font-size: 14px;
          color: #a9a9a9;
          line-height: 25px;
          margin-top: 40px;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 6;
          overflow: hidden;
        }
      }
      .btn {
        position: absolute;
        bottom: 44px;
        right: 26px;
        width: 68px;
        height: 23px;
        line-height: 23px;
        text-align: center;
        background: #ffffff;
        border-radius: 11px;
        font-size: 13px;
        color: #b5b5b5;
      }
    }
    li:hover {
      background: #ffffff;
      box-shadow: 0px 0px 22px 0px rgba(0, 0, 0, 0.14);
      border-radius: 20px;
      padding: 30px 15px;
      cursor: pointer;
      .date {
        span {
          color: #ff5e5e;
        }
      }
      .right {
        .title {
          color: #ff5e5e;
        }
      }
       .btn {
        background: #FF5E5E;
        color: #fff;
      }
    }
  }
}
</style>