<template>
  <div class="home_IT">
    <div class="title">
      <div class="font_zh">IT人力外包</div>
      <div class="font_en">IT MANPOWER OUTSOURC</div>
    </div>
    <div class="manpower_outsource">
      <ul class="tab">
        <li
          v-for="(tabName, index) in tabs"
          :key="index"
          :class="{ active: currentIndex === index }"
          @click="currentIndex = index"
          @mouseover="currentIndex = index"
        >
          {{ tabName }}
        </li>
      </ul>
      <div
        v-show="currentIndex === index"
        class="content"
        v-for="(item, index) in tabInfoList"
        :key="index"
      >
        <div class="left">
          <ul>
            <li
              v-for="(left_Item, left_index) in item.text_left"
              :key="left_index"
            >
              <div>
                <img src="@/assets/Home/icon_it_hook.png" alt="" />
                <span>{{ left_Item[0] }}</span>
              </div>
              <div>{{ left_Item[1] }}</div>
            </li>
          </ul>
        </div>
        <div class="right" v-html="item.text_right" />
      </div>
    </div>
  </div>
</template>
<script>
import { reactive, toRefs } from 'vue'

export default {
  name: 'IT',
  setup() {
    const state = reactive({
      currentIndex: 0,
      tabs: ['管理成本', '风险管理', '技术能力'],
      tabInfoList: [
        {
          text_left: [
            [
              '无人员波动烦恼',
              '我们拥有丰富的培训计划，网聚了大批IT精英，团队稳定，工作热情高。',
            ],
            [
              '无用工风险',
              '近年来随着国家相关政策的出台，企业用工成本和风险增高，在辞退员工等方面有诸多的弊端，选择我们的服务，只需每月支付固定的费用，剩下的都是我们的事。',
            ],
            [
              '无管理成本',
              '将专业的事交给专业的公司，企业按“轻公司”模式发展，无不必要的管理成本。',
            ],
          ],
          text_right:
            '流动性高<br>一般企业的网络维护技术含量不高，网管的职业发展空间有限，流动较大。<br>离职影响大<br>网管离职往往牵涉财务、行政、人事、总经理等诸多部门领导的精力，本质上就是企业需求与网管的职业发展无法匹配，形成了很尴尬的供需关系 <br>成本高 <br>增加人员编制成本高，企业必须承担员工的社保、办公管理等费用，而且随着国家政策的不断调整，用人成本一直处于上升趋势。',
        },
        {
          text_left: [
            [
              '无离职风险',
              '我们拥有完备的工程师备份机制，一旦我们的工程师不被客户认可或者自身离职，我们的后备工程师可以快速交接项目，而且，交接过程由逾仕完成，不用客户操心。',
            ],
            [
              '信息安全双保险',
              '腾翔与客户签订专门的《保密协议》，一旦发生信息安全事件，客户可以同时追究腾翔服务人员和腾翔的责任，腾翔自开展服务业务至今未发生泄密事件。',
            ],
          ],
          text_right:
            '离职风险 <br>一般公司的技术人员没有备份人员，一旦离职，快速招聘较为困难，而且招聘成本较高，同时，给公司信息安全带来风险和隐患。 <br>招聘人员不对口 <br>对于传统的企业来说，如果单独的建立自己的一个软件开发团队，不仅要招聘项目经理、美工设计、前台开发、后台开发、软件测试运维等，这些必备的专业人才，需要一段时间的磨合后才能进行合作配合，it人才外包就免去了这些烦恼了，避免磨合过渡期的人才和成本风险。',
        },
        {
          text_left: [
            [
              '技术人才灵活多样性',
              '驻场it人员的招聘是根据企业的项目开发需求决定的，且有可以根据项目开发预计的时间，自由决定所需驻场it人员的工作时间和技术方向，合理的使用it人力资源，降低公司人力成本。',
            ],
            [
              '驻场岗位广泛',
              'IT人才外包服务覆盖每一个主流的IT行业技能，包括Java、C+、.NET、Android、IOS、Web前端以及数据库设计等主流IT人才。',
            ],
          ],
          text_right:
            'it技术人员配备不全面  <br>一般企业为了节约成本，只会招聘该项目所需要的技术人员，不会配备多余的技术人员，这样就会导致企业新技术的缺乏，技术水平将会慢慢落后于同行业。  <br>技术人才紧缺  <br>在IT技术的诸多方面受限于IT工程师个人能力，当公司接到新项目用到新技术时，一般公司将无法快速招聘到合适的技术工程师。',
        },
      ],
    })

    return {
      ...toRefs(state),
    }
  }
}
</script>
<style lang="less" scoped>
.home_IT {
  padding-top: 40px;
}
.title {
  text-align: center;
  .font_en {
    height: 25px;
    font-size: 18px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #e9e9e9;
    line-height: 25px;
    margin-top: 9px;
  }
  .font_zh {
    height: 50px;
    font-size: 34px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #404040;
    line-height: 48px;
    background: url("@/assets/title_bg.png") no-repeat;
    background-size: 238px 28px;
    background-position: 50% 23px;
  }
}

.manpower_outsource {
  widows: 100%;
  max-width: 1920px;
  height: 628px;
  background: url("@/assets/Home/it_sorcing.png") no-repeat 0px center;
  background-size: 100% 100%;
  margin-top: 46px;
  & > ul.tab {
    display: flex;
    justify-content: center;
    padding-top: 57px;
    & > li {
      width: 127px;
      height: 42px;
      line-height: 42px;
      text-align: center;
      background: #ffffff;
      border-radius: 21px;
      border: 1px solid #ffbbbb;
      font-size: 16px;
      font-weight: 600;
      color: #404040;
      margin-right: 25px;
      &:hover {
        cursor: pointer;
        background: #ef0400;
        color: #fff;
      }
    }
    li.active {
      background: #ef0400;
      color: #fff;
    }
  }
  .content {
    width: 1160px;
    margin: 34px auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .left {
      background: url("@/assets/Home/it_manpower_outsource.png") no-repeat;
      width: 612px;
      height: 420px;
      background-size: 100% 100%;
      padding: 0px 47px;
      display: flex;
      align-items: center;
      li {
        & > div:nth-child(1) {
          img {
            width: 21px;
            height: 17px;
          }
          span {
            font-size: 14px;
            font-weight: 600;
            color: #ef0400;
            line-height: 26px;
            margin-left: 8px;
          }
        }
        & > div:nth-child(2) {
          font-size: 14px;
          color: #404040;
          line-height: 26px;
          margin-left: 29px;
        }
      }
    }
    .right {
      width: 462px;
      height: 226px;
      font-size: 14px;
      color: #404040;
      line-height: 26px;
    }
  }
}
</style>