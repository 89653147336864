<template>
  <div class="container">
    <div class="top">
      <div class="title">
        <div class="font_zh">定制化解决方案</div>
        <div class="font_en">THE SOLUTION</div>
      </div>
      <div class="more" @click="goMore">更多 →</div>
    </div>
    <ul class="list_box">
      <li v-for="(item, index) in list" :key="index" @click="goLink(item.path)">
        <img src="@/assets/Home/solution_bg.png" alt="" />
        <div class="info">
          <div class="title">{{ item.title }}</div>
          <div class="content">{{ item.content }}</div>
          <div class="btn">立刻查看</div>
        </div>
      </li>
    </ul>
  </div>
</template>
<script>
import { reactive, toRefs } from "vue";
import { useRouter } from "vue-router";

export default {
  name: "Solution",
  setup() {
    const state = reactive({
      list: [
        {
          path: "/solutions/enterprise",
          title: "企业培训系统",
          content:
            "恒学院企培平台是帮助企业提升员工能力、促进员工发展，为员工规划详细的培训学习路径，提升员工整体素质，是促进企业进步的可靠工具，包含课程培训、智能考试、知识学习、数据分析、案例分析、人员认证等功能，恒学院企培平台不限行业，适合各种培训学习场景，支持多种培训项目，做到有计划、有过程、有结果。企业可以根据自身的发展需要，选择适合自己的培训体系，建立有效的培训机制。",
        },
        {
          path: "/solutions/campusfeedback",
          title: "教育教学督导与评价系统",
          content:
            "教育教学督导与评价系统是一种基于信息技术构建的教学质量评估系统，包括教学信息收集、数据存储与处理、数据分析、数据可视化与反馈、评估标准制定和用户管理功能等。这些功能相互配合，共同构成了一个完整的教学督导评教系统，为学校管理层和教师提供全面、准确、可靠的教学质量评估服务，帮助学校提高教学效果和学习成效。",
        },
        {
          path: "/solutions/pharmaceuticals",
          title: "生物制药领域MES项目",
          content:
            "生物生产管理系统主要是服务于生物医药企业，抗体医药制造类企业，帮助他们对生产过程中的客户，订单，生产流程安排，任务分配，生产流程自动化管理，生产统计等不同的维度进行控制管理。通过信息化手段辅助其生产销售过程，以便于实现生产销售过程的快速有效管理，提高生产销售效率。",
        },
        {
          path: "/solutions/dataCenter",
          title: "数据中心平台",
          content:
            "大数据应用服务平台实现了征信数据的管理维护，家族氏谱的关联管理及查询服务，提供征信信息、不动产信息的便捷查询功能。系统界面亲和，操作简单，功能完善。主要功能分为以下几大模块：征信大数据、家族氏谱管理、档案管理、系统权限管理、系统参数管理，以及数据加工和数据监控功能，为客户实现信息线上化提供了工作便利，简化了作业流程，节约了工作流程时间。",
        },
        {
          path: "/solutions/PropertyMag",
          title: "物业管理APP",
          content:
            "该项目实现了语音输入,实时定位,绘制行动轨迹,消息推送等功能,便于管理员可以实时登记反馈各个店铺的运营状况,从而提高工作效率。",
        },
        {
          path: "/solutions/eco_platform",
          title: "电商平台",
          content:
            "该项目是一个线上购物商城项目,拥有PC和APP两个平台,其中PC端主要包含商城,促销活动,活动,艺术专区,VR场景等功能,给用户提供多种多样的选择,更直观的了解商品;APP端则包含首页,商城,购物车,上传作品,客服系统等功能,使得用户随时随地都可以享受购物的乐趣。",
        },
        {
          path: "/solutions/carNavigation",
          title: "汽车导航",
          content:
            "1.加快区域交通通行效率，缓解交通拥堵，保障交通安全，降低交通能耗，营造和谐、宜居、宜业城市;2.优先将智能网联技术应用在省内应用，支撑第19届亚运会，打造杭州新型智慧交通，向世界展示杭州风采;3.进一步加大浙江在智能网联产业的行业影响，率先制定国内领先的车路协同地方标准，增加行业话语权;4.快速聚集智能网联产业的高科技及创新企业，提升浙江在国内的产业地位，成为国内智能网联创新高地，形成千亿级智能产业聚集区。",
        },
        {
          path: "/solutions/TakeOutRider",
          title: "外卖骑手小程序APP",
          content:
            "外卖骑手小程序是一款在线预约菜单配送的应用系统，用户可以通过微信一键登录，或者选择手机号码验证登录。方便用户可以在微信内被便捷的获取和传播，同时具有出色的使用体验。",
        },
      ],
    });

    const router = useRouter();
    const goLink = (path) => {
      if (path === "/solutions/enterprise") {
        window.open("https://hxy.hdingruiit.com");
      } else {
        router.push(path);
      }
    };

    const goMore = () => {
      router.push("/more_solutions");
    };

    return {
      ...toRefs(state),
      goLink,
      goMore,
    };
  },
};
</script>
<style lang="less" scoped>
.container {
  width: 1300px;
  margin: 109px auto;
}
.top {
  position: relative;
  .title {
    text-align: center;
    .font_en {
      height: 25px;
      font-size: 18px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #e9e9e9;
      line-height: 25px;
      margin-top: 9px;
    }
    .font_zh {
      height: 50px;
      font-size: 30px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #404040;
      line-height: 42px;
      background: url("@/assets/title_bg.png") no-repeat;
      background-size: 238px 25px;
      background-position: 50% 23px;
    }
  }
  .more {
    width: 68px;
    height: 23px;
    line-height: 23px;
    text-align: center;
    background: #f2f2f2;
    border-radius: 11px;
    font-size: 13px;
    color: #b5b5b5;
    position: absolute;
    right: 0;
    top: 50%;
    margin-top: -11.5px;
  }
  .more:hover {
    cursor: pointer;
    color: #ef0400;
  }
}

ul.list_box {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 49px;
  li {
    width: 634px;
    height: 232px;
    background: #ffffff;
    box-shadow: 0px 2px 23px 0px rgba(0, 0, 0, 0.1);
    padding: 32px;
    position: relative;
    margin-bottom: 30px;
    img {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      display: none;
    }
    .info {
      position: absolute;
      height: 100%;
      top: 0;
      left: 0;
      width: 100%;
      padding: 32px 32px 0;
      .title {
        font-size: 20px;
        font-weight: 600;
        color: #333333;
        line-height: 28px;
      }
      .content {
        font-size: 14px;
        color: #868686;
        line-height: 20px;
        margin-top: 22px;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        overflow: hidden;
      }
      .btn {
        width: 144px;
        height: 41px;
        line-height: 41px;
        text-align: center;
        border-radius: 21px;
        border: 1px solid #868686;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #404040;
        position: absolute;
        bottom: 26px;
        right: 23px;

        &:hover {
          background: #ef0400;
          color: #fff;
          border: none;
          cursor: pointer;
        }
      }
      &:hover {
        cursor: pointer;
        .title {
          color: #ef0400;
        }
      }
    }
    &:hover img {
      display: block;
    }
  }
}
</style>
