<template>
  <!-- 导航栏 -->
  <div>
    <div class="navbar">
      <img class="logo" src="../assets/logo.png" />
      <ul class="nav">
        <li v-for="(item, index) in navList" :key="index" @mouseenter="showSubMenu(index)" @mouseleave="hideSubMenu"
          @click="goPath(item.path)">
          {{ item.name }}
          <div class="interval">
            <ul class="subMenu" :style="{
          width: item.submenu.length > 5 ? '357px' : '150px',
          height: item.submenu.length > 5 ? '178px' : '150px',
          right: item.submenu.length > 5 ? '-147px' : '-40px'
        }" v-if="item.submenu && item.submenu.length && index === currentMenuIndex">
              <!-- @mouseleave="hideSubMenu" -->
              <li v-for="(subItem, subIndex) in item.submenu" :key="subIndex" @click.stop="goPath(subItem.path)"
                :style="{ height: item.submenu.length > 5 ? '20%' : 100 / item.submenu.length + '%' }">
                {{ subItem.name }}
              </li>
            </ul>
          </div>
        </li>
        <li><img src="@/assets/icon_phone.png" />15805189384</li>
        <li>
          <button class="button" @click="onModel">在线咨询</button>
        </li>
      </ul>
    </div>
    <a-modal v-model:visible="dialogVisible" style="width: 460px;padding: 0 50px;">
      <template #title>
        <p style="font-size: 25px;color: #937142;font-weight: 700;text-align: center;margin-top: 20px;">在线咨询</p>
      </template>
      <a-form ref="formRef" :model="formState" :rules="rules">
        <!-- <p>请完善一下信息，我们会尽快联系您开通试用账号</p> -->
        <p><span style="color: red;margin-right: 5px;">*</span>姓名</p>
        <a-form-item ref="name" name="name">
          <a-input v-model:value="formState.name" placeholder="请输入姓名" />
        </a-form-item>
        <p><span style="color: red;margin-right: 5px;">*</span>手机号</p>
        <a-form-item ref="phone" name="phone">
          <a-input v-model:value="formState.phone" placeholder="请输入手机号" />
        </a-form-item>
        <p><span style="color: red;margin-right: 5px;">*</span>验证码</p>
        <a-form-item ref="reCaptcha" name="reCaptcha">
          <a-input style="width: 250px;margin-right: 15px;" v-model:value="formState.reCaptcha" placeholder="请输入验证码" />
          <a-button @click="reCaptchas()" :disabled="isCounting" :style="{ color: '#fff', background: '#937142' }">
            {{ countdownText }}
          </a-button>
        </a-form-item>
        <p><span style="color: red;margin-right: 5px;">*</span>公司</p>
        <a-form-item ref="company" name="company">
          <a-input v-model:value="formState.company" placeholder="请输入完整公司名称" />
        </a-form-item>
      </a-form>
      <template #footer>
        <div style="border-top: none;">
          <!-- <a-button key="back" @click="dialogVisible = false">取消</a-button> -->
          <a-button key="submit" class="botttomButton" type="primary" @click="handleOk">确认提交</a-button>
        </div>
      </template>
    </a-modal>
  </div>
</template>
<script>
import { computed, reactive, toRefs, ref, onMounted, onBeforeUnmount } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { getPhoneAPI, saveAndUpdateAPI } from "../api/index"
import { message } from 'ant-design-vue';

export default {
  name: 'Nav',
  setup() {
    const store = useStore()
    const navList = computed(() => store.state.navList)
    const info = reactive({
      currentMenuIndex: -1
    })
    let isCounting = ref(false)
    const countdownSeconds = ref(60);
    let dialogVisible = ref(false)
    const formRef = ref()
    let countdownInterval = ref()
    const formState = reactive({
      name: '',
      reCaptcha: "",
      phone: '',
      company: ''
    });
    const rules = reactive(
      {
        name: [
          { required: true, message: '请输入姓名', trigger: 'blur' },
          { pattern: /^[\u4e00-\u9fa5]{2,5}$/, message: '请输入2-5个中文', trigger: 'blur' }
        ],
        reCaptcha: [{ required: true, message: '请输入验证码', trigger: 'blur' }],
        company: [
          { required: true, message: '请输入公司名称', trigger: 'blur' },
          { pattern: /^[\u4e00-\u9fa5]{0,20}$/, message: '请输入20个以内的中文', trigger: 'blur' }
        ],
        phone: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { pattern: /^1[3456789]\d{9}$/, message: '请输入正确的手机号', trigger: 'blur' }
        ]
      }
    );
    const onModel = () => {
      dialogVisible.value = true
      countdownSeconds.value = 60
      isCounting.value = false
      stopCountdown();
      if (formRef.value) {
        formRef.value.resetFields()
      }
    }
    const showSubMenu = (index) => {
      info.currentMenuIndex = index
    }

    const hideSubMenu = () => {
      info.currentMenuIndex = -1
    }

    const router = useRouter()
    const goPath = (path) => {
      if ((path.includes('about') && path !== '/about') || path === '/#provide') {
        window.location.href = path
      } else if (path==='/solutions/enterprise'){
        window.open('https://hxy.hdingruiit.com')
      } else {
        router.push(path)
      }
      info.currentMenuIndex = -1
    }
    const reCaptchas = () => {
      let err = /^1[3456789]\d{9}$/
      if (formState.phone && formState.name && err.test(formState.phone)) {
        getPhoneAPI({ phone: formState.phone }).then(res => {
          if (res.code == 0) {
            startCountdown()
          }
        }).catch(error=>{
          message.error(error.message)
        })
      } else if (!err.test(formState.phone)) {
        message.warning("请输入正确的手机号")
      }else {
        message.warning("请先输入手机号与姓名")
      }
    }
    const handleOk = async () => {
      let validate = await formRef.value.validate()
      if (!validate) {
        return
      } else {
        let params = {
          phoneCode: formState.reCaptcha,
          entity: {
            leads_name: formState.company,
            mobile: formState.phone,
            remark: formState.name,
            option: "恒鼎锐官网"
          },
        }
        saveAndUpdateAPI(params).then(res => {
          if (res.code == 0) {
            message.success("提交成功")
            dialogVisible.value = false
            if (formRef) {
              formRef.value.resetFields()
            } else {
              message.error("提交失败")
            }
          }
        }).catch(error => {
          message.error(error.message)
        })
      }
    }


    // 开始倒计时
    const startCountdown = () => {
      if (!isCounting.value) {
        isCounting.value = true;
        countdownInterval.value = setInterval(() => {
          if (countdownSeconds.value > 0) {
            countdownSeconds.value--;
          } else {
            stopCountdown();
          }
        }, 1000);
      }
    }
    // 停止倒计时
    const stopCountdown = () => {
      isCounting.value = false;
      clearInterval(countdownInterval.value);
      countdownSeconds.value = 60;
    };
    const countdownText = computed(() => {
      if (isCounting.value == true) {
        return `${countdownSeconds.value}秒`;
      } else {
        return '获取验证码';
      }
    })
    onMounted(() => { })
    onBeforeUnmount(() => {
      stopCountdown();
      // 组件卸载前执行的逻辑
    });

    return {
      navList,
      ...toRefs(info),
      showSubMenu,
      hideSubMenu,
      goPath,
      onModel,
      dialogVisible,
      handleOk,
      formRef,
      formState,
      rules,
      reCaptchas,
      isCounting,
      countdownText
    }
  }
}
</script>
<style lang="less" scoped>
.logo {
  width: 146px;
  height: 40px;
  margin-left: 315px;
}

.navbar {
  height: 80px;
  width: 100%;
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  background: #ffffff;

  .nav {
    margin: 0;
    margin-left: 150px;
    height: 80px;
  }

  .nav>li {
    padding: 0;
    margin-right: 97px;
    float: left;
    display: block;
    color: #333;
    font-size: 16px;
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;

    &:hover {
      color: #ef0400;
    }

    .interval {
      width: 100%;
      position: absolute;
      bottom: -5px;
      height: 5px;

      .subMenu {
        position: absolute;
        top: 5px;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        box-sizing: content-box;
        background: #fff;
        border-radius: 8px;
        padding: 15px 0;

        li {
          width: 45%;
          color: #333333;
          font-size: 14px;
          text-align: left;
          font-weight: 500;
          display: flex;
          align-items: center;

          &:nth-child(1),
          &:nth-child(2),
          &:nth-child(3),
          &:nth-child(4),
          &:nth-child(5) {
            margin-left: 47px;
          }
        }

        li:hover {
          color: #ef0400;
        }
      }
    }
  }

  .nav>li:hover {
    cursor: pointer;
  }

  .nav>li:last-child {
    display: flex;
    align-items: center;
    cursor: default;

    img {
      width: 15px;
      height: 15px;
      margin-right: 10px;
    }
  }
}

@media screen and (max-width: 1800px) {
  .logo {
    margin-left: 10%;
  }

  .navbar {
    .nav {
      width: 65%;
      margin-left: 15%;

      &>li {
        margin-right: 7%;
      }
    }
  }
}

.button {
  border: none;
  border-radius: 5px;
  background-color: #A17B46;
  color: #fFF;
  width: 100px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  cursor: pointer;
}

.dialog_box {
  width: 460px;
  height: 675px;
  background: #ffffff;
  border-radius: 4px;
  z-index: 999;
  padding: 15px 50px 0;

  .dialog_title {
    font-family: AlibabaPuHuiTiM;
    font-size: 34px;
    color: #937142;
    line-height: 47px;
    text-align: center;
    font-weight: 900;
    margin-bottom: 24px;
  }

  .dialog_title1 {
    font-family: AlibabaPuHuiTiR;
    font-size: 14px;
    color: #666666;
    line-height: 20px;
    text-align: left;
    margin-bottom: 24px;
  }

}

modelTitle {
  font-size: 50px;
}

.ant-form-item-control-input-content {
  border: none;
}

.botttomButton {
  width: 300px;
  border: none;
  margin-right: 50px;
  background: #937142;
  color: #fff;
  height: 40px;
}

/deep/ .ant-modal .ant-modal-footer {
  border-top: none;
}
</style>
