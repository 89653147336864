<template>
  <div class="home_Practice">
    <div class="title">
      <div class="font_zh">优质合作客户</div>
      <div class="font_en">COOPERATIVE PARTNER</div>
    </div>
  </div>
  <a-carousel class="parent" dotsClass="dots">
    <div>
      <img v-lazy="require('@/assets/Home/parent1.png')" />
    </div>
    <div>
      <img v-lazy="require('@/assets/Home/parent2.png')" />
    </div>
    <div>
      <img v-lazy="require('@/assets/Home/parent3.png')" />
    </div>
  </a-carousel>
</template>
<script>
import { reactive, toRefs } from 'vue'

export default {
  name: 'PracticeCertification',
  setup() {
    const state = reactive({
    })

    return {
      ...toRefs(state),
    }
  }
}
</script>
<style lang="less" scoped>
.home_Practice {
  padding-top: 68px;
}
.title {
  text-align: center;
  .font_en {
    height: 25px;
    font-size: 18px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #e9e9e9;
    line-height: 25px;
    margin-top: 9px;
  }
  .font_zh {
    height: 50px;
    font-size: 34px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #404040;
    line-height: 48px;
    background: url("@/assets/title_bg.png") no-repeat;
    background-size: 238px 28px;
    background-position: 50% 23px;
  }
}
.parent {
  margin-top: 50px;
  height: 380px;
}
img {
  width: 1300px;
  height: 330px;
  margin: 0 auto;
}
.ant-carousel :deep(.dots) {
  bottom: -36px;
}
.ant-carousel :deep(.slick-dots li button) {
  background: #d8d8d8;
  width: 45px;
  height: 5px;
}
.ant-carousel :deep(.slick-dots li.slick-active button) {
  background: #2083ee;
}
</style>