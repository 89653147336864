<template>
  <div class="container">
    <div class="top">
      <div class="title">
        <div class="font_en">THE SOLUTION</div>
        <div class="font_zh">定制化解决方案</div>
      </div>
      <div class="more" @click="goMore">更多 →</div>
    </div>
    <ul class="list_box">
      <li
        v-for="(item, index) in list"
        :key="index"
        @click="goLink(item.path)"
        :style="{ 'background-image': `url(${item.bg})` }"
      >
        <div>{{ item.title }}</div>
      </li>
    </ul>
  </div>
</template>
<script>
import { reactive, toRefs } from "vue";
import { useRouter } from "vue-router";

export default {
  name: "Solution",
  setup() {
    const state = reactive({
      list: [
        {
          path: "/solutions/enterprise",
          title: "企业培训系统",
          bg: require("@/assets/Home/scheme9.png"),
        },
        {
          path: "/solutions/campusfeedback",
          title: "教育教学督导与评价系统",
          bg: require("@/assets/Home/scheme8.png"),
        },
        {
          path: "/solutions/pharmaceuticals",
          title: "生物制药领域MES项目",
          bg: require("@/assets/Home/scheme1.png"),
        },
        {
          path: "/solutions/TakeOutRider",
          title: "外卖骑手小程序APP",
          bg: require("@/assets/Home/scheme2.png"),
        },
        {
          path: "/solutions/PropertyMag",
          title: "物业管理APP",
          bg: require("@/assets/Home/scheme3.png"),
        },
        {
          path: "/solutions/dataCenter",
          title: "数据中心平台",
          bg: require("@/assets/Home/scheme4.png"),
        },
        {
          path: "/solutions/erp",
          title: "ERP项目",
          bg: require("@/assets/Home/scheme5.png"),
        },
        {
          path: "/solutions/carNavigation",
          title: "汽车导航",
          bg: require("@/assets/Home/scheme6.png"),
        },
        {
          path: "/solutions/eco_platform",
          title: "电商平台",
          bg: require("@/assets/Home/scheme7.png"),
        },
        // {
        //   path: "/solutions/userManSystem",
        //   title: "用户管理系统",
        //   bg: require("@/assets/Home/scheme8.png"),
        // },
        // {
        //   path: "/solutions/serviceManSystem",
        //   title: "服务管理系统",
        //   bg: require("@/assets/Home/scheme9.png"),
        // },
      ],
    });

    const router = useRouter();
    const goLink = (path) => {
      if (path === "/solutions/enterprise") {
        window.open("https://hxy.hdingruiit.com");
      } else {
        router.push(path);
      }
    };

    const goMore = () => {
      router.push("/more_solutions");
    };

    return {
      ...toRefs(state),
      goLink,
      goMore,
    };
  },
};
</script>
<style lang="less" scoped>
.container {
  width: 100%;
  margin: 30px auto;
}
.top {
  position: relative;
  .title {
    text-align: center;
    .font_en {
      font-size: 16px;
      font-weight: 600;
      color: #e9e9e9;
      line-height: 16px;
    }
    .font_zh {
      font-size: 21px;
      font-weight: 600;
      color: #333;
      line-height: 20px;
    }
  }
  .more {
    width: 68px;
    height: 23px;
    line-height: 23px;
    text-align: center;
    background: #f2f2f2;
    border-radius: 11px;
    font-size: 13px;
    color: #b5b5b5;
    position: absolute;
    right: 0;
    top: 50%;
    margin-top: -11.5px;
  }
  .more:hover {
    cursor: pointer;
    color: #ef0400;
  }
}
.list_box {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 10px 5px;
  li {
    width: calc((100vw - 40px) / 3);
    height: calc((100vw - 40px) / 3 / 112 * 84);
    // background: aqua;
    border-radius: 10px;
    margin: 10px 5px;
    position: relative;
    overflow: hidden;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    div {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 30px;
      line-height: 30px;
      font-size: 12px;
      text-align: center;
      color: #fff;
      background: rgba(0, 0, 0, 0.5);
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}
</style>
