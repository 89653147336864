<template>
  <div class="home_IT">
    <div class="title">
      <div class="font_en">IT MANPOWER OUTSOURC</div>
      <div class="font_zh">IT人力外包</div>
    </div>
    <a-tabs v-model:activeKey="currentIndex">
      <a-tab-pane
        :key="index"
        :tab="item.tab"
        v-for="(item, index) in tabInfoList"
      >
        <div class="it_box" v-for="(child, i) in item.textList" :key="i">
          <div class="it_title">
            <img src="@/assets/Home/icon_it_hook_1.png" alt="" />
            <div>{{ child.title }}</div>
          </div>
          <div class="it_content">{{ child.content }}</div>
        </div>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>
<script>
import { reactive, toRefs } from "vue";

export default {
  name: "IT",
  setup() {
    const state = reactive({
      currentIndex: 0,
      tabInfoList: [
        {
          tab: "管理成本",
          textList: [
            {
              title: "无人员波动烦恼",
              content:
                "我们拥有丰富的培训计划，网聚了大批IT精英，团队稳定，工作热情高。",
            },
            {
              title: "无用工风险",
              content:
                "近年来随着国家相关政策的出台，企业用工成本和风险增高，在辞退员工等方面有诸多的弊端，选择我们的服务，只需每月支付固定的费用，剩下的都是我们的事。",
            },
            {
              title: "无管理成本",
              content:
                "将专业的事交给专业的公司，企业按“轻公司”模式发展，无不必要的管理成本。",
            },
          ],
        },
        {
          tab: "风险管理",
          textList: [
            {
              title: "无离职风险",
              content:
                "我们拥有完备的工程师备份机制，一旦我们的工程师不被客户认可或者自身离职，我们的后备工程师可以快速交接项目，而且，交接过程由逾仕完成，不用客户操心。",
            },
            {
              title: "信息安全双保险",
              content:
                "腾翔与客户签订专门的《保密协议》，一旦发生信息安全事件，客户可以同时追究腾翔服务人员和腾翔的责任，腾翔自开展服务业务至今未发生泄密事件。",
            },
          ],
        },
        {
          tab: "技术能力",
          textList: [
            {
              title: "技术人才灵活多样性",
              content:
                "驻场it人员的招聘是根据企业的项目开发需求决定的，且有可以根据项目开发预计的时间，自由决定所需驻场it人员的工作时间和技术方向，合理的使用it人力资源，降低公司人力成本。",
            },
            {
              title: "驻场岗位广泛",
              content: "IT人才外包服务覆盖每一个主流的IT行业技能，包括Java、C+、.NET、Android、IOS、Web前端以及数据库设计等主流IT人才。",
            },
          ],
        },
      ],
    });

    return {
      ...toRefs(state),
    };
  },
};
</script>
<style lang="less" scoped>
.title {
  text-align: center;
  .font_en {
    font-size: 16px;
    font-weight: 600;
    color: #e9e9e9;
    line-height: 16px;
  }
  .font_zh {
    font-size: 21px;
    font-weight: 600;
    color: #333;
    line-height: 20px;
  }
}
:deep(.ant-tabs-nav-scroll) {
  display: flex;
  justify-content: center;
}
:deep(.ant-tabs-tab) {
  color: #868686;
  font-size: 16px;
}
:deep(.ant-tabs-tab-active.ant-tabs-tab) {
  color: #333333;
  font-weight: bold;
  // line-height: 30px;
}
// :deep(.ant-tabs-ink-bar) {
//   height: 3px;
//   bottom: 10px;
// }
.it_box {
  padding: 0 15px;
  .it_title {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: bold;
    color: #2083ee;
    img {
      display: block;
      width: 19px;
      height: 15px;
      margin-right: 15px;
    }
  }
  .it_content {
    font-size: 14px;
    color: #666666;
    margin: 8px 0;
  }
}
</style>
