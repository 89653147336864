import { createStore } from "vuex";

export default createStore({
  state: {
    navList: [
      { name: "首页", path: "/" },
      { name: "服务", path: "/#provide" },
      {
        name: "解决方案",
        path: "/solutions",
        submenu: [
          { name: "企业培训系统", path: "/solutions/enterprise" },
          { name: "教育教学督导与评价系统", path: "/solutions/campusfeedback" },
          { name: "生物制药领域MES", path: "/solutions/pharmaceuticals" },
          { name: "数据中心", path: "/solutions/dataCenter" },
          { name: "物业管理APP", path: "/solutions/PropertyMag" },
          { name: "电商平台", path: "/solutions/eco_platform" },
          { name: "汽车导航", path: "/solutions/carNavigation" },
          { name: "外卖骑手小程序", path: "/solutions/TakeOutRider" },
          { name: "Erp项目", path: "/solutions/erp" },
          { name: "快信通项目", path: "/solutions/fastComProject" },
          { name: "服务管理系统", path: "/solutions/serviceManSystem" },
          { name: "用户管理系统", path: "/solutions/userManSystem" },
        ],
      },
      { name: "IT人力外包", path: "/ITManpowerOutsourcing" },
      {
        name: "关于恒鼎锐",
        path: "/about",
        submenu: [
          { name: "企业概况", path: "/about#Survey",label:'Survey' },
          { name: "核心价值", path: "/about#CoreValue",label:'CoreValue' },
          { name: "企业风采", path: "/about#Style",label:'Style' },
          { name: "企业荣誉", path: "/about#Honor",label:'Honor' },
        ],
      },
    ],
  },
  getters: {},
  mutations: {},
  actions: {},
  modules: {},
});
